// import fetchJsonp from 'fetch-jsonp';
import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const listVehicles = parameters =>
  api.get(`/admin/vehicle${objectToURLParams(parameters)}`);

export const post = payload => api.post('/admin/organization/vehicle', payload);

export const put = (id, payload) => api.put(`/admin/vehicle/${id}`, payload);

export const remove = id => api.delete(`/admin/vehicle/${id}`);

export const getDataByLicensePlate = licensePlate =>
  api.get(`/vehicle/plate/${licensePlate}`);

export const undelete = id => api.put(`/vehicle/undelete/${id}`);

export const getVehicleStatement = (id, parameters) =>
  api.get(`/v2/user/statement${objectToURLParams(parameters)}&_vehicle=${id}`);
