export const FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST =
  'FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST';
export const FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS =
  'FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS';
export const FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR =
  'FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR';

export const FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_REQUEST =
  'FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_REQUEST';
export const FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_SUCCESS =
  'FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_SUCCESS';
export const FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_ERROR =
  'FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_ERROR';
export const FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_RESET =
  'FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_RESET';

export const listFuelManagementTransactions = params => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST,
  payload: { params },
});

export const listFuelManagementTransactionsSuccess = payload => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS,
  payload,
});

export const listFuelManagementTransactionsError = message => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR,
  payload: { message },
});

export const cancelFuelManagementTransaction = (id, payload) => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_REQUEST,
  payload: { id, payload },
});

export const cancelFuelManagementTransactionSuccess = payload => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_SUCCESS,
  payload,
});

export const cancelFuelManagementTransactionError = message => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_ERROR,
  payload: { message },
});

export const cancelFuelManagementTransactionReset = () => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_RESET,
});
