import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as orgSettings from './actions';

function* listOrgSettings(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const organizationsSettings = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };
    yield put(
      orgSettings.listOrgSettingsSuccess({
        list: organizationsSettings,
        pages,
        items,
      })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(orgSettings.listOrgSettingsError(message));
  }
}

export function* watchListOrgSettings(api) {
  yield takeEvery(orgSettings.ORG_SETTINGS_LIST_REQUEST, listOrgSettings, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListOrgSettings, api)]);
}
