export const ORG_SETTINGS_LIST_REQUEST = 'ORG_SETTINGS_LIST_REQUEST';
export const ORG_SETTINGS_LIST_SUCCESS = 'ORG_SETTINGS_LIST_SUCCESS';
export const ORG_SETTINGS_LIST_ERROR = 'ORG_SETTINGS_LIST_ERROR';

export const listOrgSettingsRequest = params => ({
  type: ORG_SETTINGS_LIST_REQUEST,
  payload: { params },
});

export const listOrgSettingsSuccess = payload => ({
  type: ORG_SETTINGS_LIST_SUCCESS,
  payload,
});

export const listOrgSettingsError = message => ({
  type: ORG_SETTINGS_LIST_ERROR,
  payload: { message },
});
