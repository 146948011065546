import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/user${objectToURLParams(parameters)}`);

export const post = body => api.post(`/admin/user`, { ...body });

export const edit = (id, user) => api.put(`/admin/user/${id}`, user);

export const remove = id => api.delete(`/admin/user/${id}`);

export const getUserByIdentifier = identifier =>
  api.get(`/user/identifier/${identifier}`);

export const getUserById = identifier => api.get(`/user/id/${identifier}`);

export const undelete = (id, organizationId) =>
  api.put(`/user/undelete/${id}?organizationId=${organizationId}`);

export const getStatement = (id, parameters) =>
  api.get(`/v2/user/statement${objectToURLParams(parameters)}&_user=${id}`);
