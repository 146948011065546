import produce from 'immer';

import {
  FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST,
  FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS,
  FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR,
  FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_REQUEST,
  FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_SUCCESS,
  FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_ERROR,
  FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_RESET,
} from './actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  cancelTransaction: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_REQUEST: {
        draft.cancelTransaction.loading = true;
        draft.cancelTransaction.error = '';
        break;
      }

      case FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_SUCCESS: {
        draft.cancelTransaction.loading = false;
        draft.cancelTransaction.error = '';
        draft.cancelTransaction.success = true;
        break;
      }

      case FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_ERROR: {
        draft.cancelTransaction.loading = false;
        draft.cancelTransaction.success = false;
        draft.cancelTransaction.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_RESET: {
        draft.cancelTransaction.loading = false;
        draft.cancelTransaction.error = '';
        draft.cancelTransaction.success = false;
        break;
      }

      default:
    }
  });
};
