import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import {
  listFuelManagementTransactionsSuccess,
  listFuelManagementTransactionsError,
  FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST,
  FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_REQUEST,
  cancelFuelManagementTransactionSuccess,
  cancelFuelManagementTransactionError,
} from './actions';

function* listFuelManagementTransactions(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listFuelManagementTransactionsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listFuelManagementTransactionsError(message));
  }
}

function* cancelFuelManagementTransaction(api, { payload }) {
  const { id, payload: payloadData } = payload;
  const response = yield call(api.cancel, id, payloadData);

  if (response.ok) {
    yield put(cancelFuelManagementTransactionSuccess(response.data));
  } else {
    yield put(cancelFuelManagementTransactionError(response.message));
  }
}

export function* watchListTransactions(api) {
  yield takeEvery(
    FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST,
    listFuelManagementTransactions,
    api
  );
}

export function* watchCancelTransaction(api) {
  yield takeEvery(
    FUEL_MANAGEMENT_TRANSACTIONS_CANCEL_REQUEST,
    cancelFuelManagementTransaction,
    api
  );
}
export default function* rootSaga(api) {
  yield all([
    fork(watchListTransactions, api),
    fork(watchCancelTransaction, api),
  ]);
}
